[class^="ant-form-item-explain"] {
  text-align: right;
  font-size: smaller;
}

.accountsTable .ant-table-content,
.ant-modal-body,
.ant-modal {
  overflow: unset !important;
}

.accountsTable table th {
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
}

.ant-table-thead th {
  padding: 8px 16px !important;
}

.ant-table-column-has-sorters:hover {
  background-color: rgb(233, 233, 233) !important;
  opacity: 1 !important;
}

#scrollableDiv {
  height: calc(100vh - 295px) !important;
  overflow: auto;
}

#scrollableDiv .infinite-scroll-component {
  overflow: unset !important;
}

.active {
  text-decoration: underline !important;
  text-decoration-thickness: 2px !important;
  text-underline-offset: 8px;
}

.anticon {
  display: inline-flex !important;
}

.ant-typography-copy,
.ant-btn-icon-only {
  color: #ffc107 !important;
}

amplify-authenticator {
  --amplify-primary-color: #ffc107;
}
