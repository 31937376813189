@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply text-gray-dark;
  }
}

@layer components {
.nav-link {
  @apply py-4 px-2 w-fit;
}
.page-title {
  @apply text-2xl mb-5;
}
.table-link-underline {
  @apply underline hover:underline
}
.nav-container {
  @apply lg:w-3/4 flex flex-col container mx-auto px-4
}
}
